import { batch } from 'react-redux';
import {
  addMonths,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  format,
  isBefore,
  isWeekend,
  lastDayOfMonth,
  subMonths,
} from 'date-fns';
import Router from 'next/router';

import DangerouslySetInnerHTML from '../components/DangerouslySetInnerHTML/DangerouslySetInnerHTML';
import {
  BHR_SCHEMA_PARAMS,
  CURRENCY_SIGNS,
  DATE,
  FIXED_HOLIDAY,
  GMP_SCHEMA_PARAMS,
  MONTHS_SHORT,
  REPORT_TIME_FORMAT_OPTIONS,
  REPORTED_TIME,
  TRASHED,
  UPDATED_AT,
} from '../constants/common';
import {
  REVIEW_STATUS_NEW,
  STATUS,
  STATUS_INACTIVE,
  STATUS_PENDING,
  VACATION_STATUSES
} from '../constants/status';
import {
  AGREEMENT, ALERT_ERROR, ESTIMATION, GOOGLE_FOLDER, JIRA_PROJECT, PRESALE_TICKET, PROJECT_LINK_ON_WORKS,
  REPOSITORIES, ROAD_MAP, SLACK_CHANNEL, STAGING_SERVER
} from '../constants/types';
import {
  // IS_USE_BUILDER_FLAG,
  MINUTES_IN_HOUR,
  PROJECT_BUDGET_ATTENTION,
  PROJECT_BUDGET_WARNING,
  SECONDS_IN_HOUR
} from '../constants/values';
import { getCorrectDate } from './dateHelper';

export const isObject = (obj) => {
  return obj && obj.constructor === Object;
};

export const isEmptyObject = (obj) => {
  return isObject(obj) && Object.keys(obj).length === 0;
};

const getQueryKeyPath = (keys) => {
  const tempKeys = [...keys];
  const mainKey = tempKeys.shift();

  return tempKeys.reduce((acc, item) => {
    return `${acc}[${item}]`;
  }, `${mainKey}`);
};

export const queryStringify = (params) => {
  const query = new URLSearchParams();

  const appendQuery = (data, prevKeys = []) => {
    Object
      .entries(data)
      .filter(([, value]) => value !== null && value !== undefined && value !== 0 && value?.length !== 0)
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            const keyPath = getQueryKeyPath([...prevKeys, key, '']);
            query.append(keyPath, item);
          });
        } else if (isObject(value)) {
          appendQuery(value, [...prevKeys, key]);
        } else {
          const keyPath = getQueryKeyPath([...prevKeys, key]);
          let updateValue = `${value}`;

          if (typeof value === 'boolean') {
            updateValue = value ? '1' : '0';
          }

          query.append(keyPath, updateValue);
        }
      });
  };

  appendQuery(params);

  return query
    .toString()
    .replace(/%5B/g, '[')
    .replace(/%5D/g, ']');
};

export const urlToFile = (url, filename, mimeType = 'image/png') => {
  return (fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }))
  );
};

export const filterQueryParams = (params, skipParams = ['file_id', 'confirm']) => {
  const appendParams = (data) => {
    let temp = {};

    Object.entries(data)
      .filter(([key, value]) => skipParams.includes(key) || typeof value === 'boolean' || (value && value.length !== 0))
      .forEach(([key, value]) => {
        const isFile = value instanceof File;

        if (typeof value === 'object' && value !== null && !Array.isArray(value) && !isFile) {
          const newValue = appendParams(value);

          if (Object.keys(newValue).length) {
            temp = {
              ...temp,
              [key]: newValue
            };
          }
        } else {
          temp = {
            ...temp,
            ...{ [key]: value }
          };
        }
      });

    return temp;
  };

  return params ? appendParams(params) : {};
};

export const parseQuery = (query, arrays = [], skipKeys = []) => {
  let tempQuery;
  const entries = Object.entries(query).filter(([key]) => !skipKeys.includes(key));

  entries.forEach(([key, value]) => {
    if (arrays.includes(key)) {
      tempQuery = {
        ...tempQuery,
        [key]: value.split(',')
      };
    } else {
      tempQuery = {
        ...tempQuery,
        [key]: (value === 'true' || value === 'false') ? value === 'true' : value
      };
    }
  });

  return tempQuery;
};

export const assignNullToUndefined = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = value === undefined ? null : value;
    return acc;
  }, {});
};

export const isLogTime = (value) => {
  const regExp = new RegExp(
    '(^\\d+[h]?$)|(^([0]|[1-5][0]+)[?:\\s*(m|min|minutes)]$)|(^\\d+h[ ]?([0]|[1-5][0]+)(?:\\s*(m|min|minutes))$)'
    + '|(^[0-9]*[.,][0,5]*$)|(^[0-9]*[.,]+([0]|[1-5][0])+(?:\\s*(m|min|minutes))$)|(^[0-9]*[.,][0-9]+(h)$)'
  );

  return value.match(regExp);
};

export const isLogTimeWithoutStep = (value) => {
  const regExp = new RegExp(
    '(^\\d+[h]?$)|(^([0]|[1-5]?[0-9])[?:\\s*(m|min|minutes)]$)|(^\\d+h[ ]?([0]|[1-5]?[0-9])(?:\\s*(m|min|minutes))$)'
    + '|(^[0-9]*[.,][0,5]*$)|(^[0-9]*[.,]+([0]|[1-5]?[0-9])+(?:\\s*(m|min|minutes))$)|(^[0-9]*[.,][0-9]+(h)$)'
  );

  return value.match(regExp);
};

export const isInteger = (value) => {
  return /^\d+$/i.test(value);
};

export const isDouble = (value) => {
  return /^\d+(\.\d+)?$/i.test(value);
};

export const toHoursAndMinutes = (totalSeconds, formatStr = 'h m') => {
  let result = '';
  const totalMinutes = Math.floor(totalSeconds / 60);
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;

  switch (formatStr) {
    case 'h:m':
      result = `${hours}:${minutes}`;
      break;
    case 'hh:mm': {
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      if (hours < 10) {
        hours = `0${hours}`;
      }

      result = `${hours}:${minutes}`;
      break;
    }
    case 'h m':
      result = `${hours}h ${minutes}m`;
      break;
    default:
      result = `${hours}h ${minutes}m`;
      break;
  }

  return result;
};

export const toHour = (ss = 0) => {
  return Math.floor(ss / SECONDS_IN_HOUR);
};

export const toSeconds = (h = 0) => {
  return Math.floor(h * SECONDS_IN_HOUR);
};

export const secondsToHoursRounded = (ss, roundTo = 2) => {
  const hh = ss / SECONDS_IN_HOUR;
  return `${parseFloat(hh.toFixed(roundTo))}h`;
};

export const secondsToHour = (ss = 0, flag = false) => {
  const hours = toHour(ss);
  const minutes = Math.round(((ss / MINUTES_IN_HOUR) % MINUTES_IN_HOUR) / 5) * 5;
  let result;

  if (flag) {
    result = `${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}`;
  } else if (hours === 0 && minutes === 0) {
    result = '0h';
  } else if (hours === 0) {
    result = `${minutes}m`;
  } else if (minutes === 0) {
    result = `${hours}h`;
  } else if (minutes === 60) {
    result = `${hours + 1}h`;
  } else {
    result = `${hours}h ${minutes}m`;
  }

  return result;
};

export const hourToSeconds = (h = '') => {
  let [hours, minutes] = h.split(':');
  hours = +hours;
  minutes = +minutes;

  return hours * 3600 + minutes * 60;
};

export const parseWorklogTimeToSeconds = (value = '') => {
  const splitArray = value.split(/([a-z])/);
  let seconds = 0;

  splitArray.forEach((item, index) => {
    if (splitArray[index + 1] === 'm') {
      seconds += parseInt(item) * MINUTES_IN_HOUR;
    } else if (splitArray[index + 1] === 'h' || index === 0) {
      seconds += item.replace(',', '.') * SECONDS_IN_HOUR;
    }
  });

  return seconds;
};

export const isAllowed = (permissions, userPermissions) => (
  permissions.some(
    (p) => userPermissions.includes(p)
  )
);

export const changeDateFormat = (date, flag) => {
  const day = new Date(date);
  const yyyy = day.getFullYear();
  let dd = day.getDate();
  let mm = day.getMonth() + 1;

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }

  return flag ? `${yyyy}-${mm}-${dd}` : `${dd}.${mm}.${yyyy}`;
};

export const getTimeExperience = (date) => {
  let result = '-';

  if (date) {
    const today = new Date();
    const fromDate = new Date(date);
    const diffTime = Math.abs(today - fromDate);
    const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
    const weeksDiff = Math.floor(diffDays / 7);
    const yearsDiff = today.getFullYear() - fromDate.getFullYear();
    const monthDiff = (yearsDiff * 12) + (today.getMonth() - fromDate.getMonth());
    result = `${diffDays} days`;

    if (yearsDiff > 0) {
      result = `${yearsDiff} years`;
    } else if (monthDiff > 0) {
      result = `${monthDiff} month`;
    } else if (weeksDiff > 0) {
      result = `${weeksDiff} week`;
    }
  }
  return result;
};

export const isError = (errors, itemId, message) => (
  errors.some((elem) => elem.id === itemId && message.includes(elem.error))
);

export const removeError = (errors, itemId, errorName) => {
  const removeIndex = errors.findIndex((error) => error.id === itemId && error.error === errorName);
  return errors.filter((error, errorIndex) => errorIndex !== removeIndex);
};

export const formatStringDate = (date, formatStr = 'dd MMM yyy') => {
  const correctDate = getCorrectDate(date);

  return format(correctDate, formatStr);
};

export const getFirstDay = (date) => {
  return format(new Date(date), 'yyyy-MM-01');
};

export const getLastDay = (date) => {
  return format(lastDayOfMonth(new Date(date)), 'yyyy-MM-dd');
};

export const formatISODate = (date, formatStr = 'dd MMMM yyyy') => {
  let formatDate = '';

  if (date) {
    const dateArray = date.split('T')[0].split('-');
    const monthIndex = parseInt(dateArray[1]) - 1;

    switch (formatStr) {
      case 'dd MMMM yyyy':
        formatDate = `${dateArray[2]} ${MONTHS_SHORT[monthIndex]} ${dateArray[0]}`;
        break;
      default:
        formatDate = `${dateArray[2]} ${MONTHS_SHORT[monthIndex]} ${dateArray[0]}`;
        break;
    }
  } else {
    formatDate = 'Not valid date';
  }

  return formatDate;
};

export const parseDate = (date, flag = false) => {
  let newDate;
  if (flag) {
    const value = date.split('.');
    newDate = new Date(value[2], value[1] - 1, value[0]);
  } else {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    newDate = `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`;
  }

  return newDate;
};

export const parseMonthIntToObj = (monthInt) => {
  let year;
  let month;

  if (monthInt) {
    const str = monthInt.toString();
    year = parseInt(str.slice(0, 4));
    month = str.slice(4) - 1;
  } else {
    const date = new Date();
    year = date.getFullYear();
    month = date.getMonth() + 1;
  }

  return { month, year };
};

export const parseToMonthInt = (year, month) => {
  return parseInt(`${year}${month >= 10 ? month : `0${month}`}`);
};

export const parseDatepicker = (date, isWithYear = true, hoursAndMinutes = false) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  let formattedDate = `${day < 10 ? `0${day}` : day} ${MONTHS_SHORT[month]}${isWithYear ? ` ${year}` : ''}`;

  if (hoursAndMinutes) {
    const now = new Date();
    const diffInMinutes = Math.floor((now - date) / 60000);

    if (diffInMinutes < MINUTES_IN_HOUR) {
      formattedDate = `${diffInMinutes} mins ago`;
    } else {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      formattedDate = `${formattedDate}, ${hours}:${minutes}`;
    }
  }

  return formattedDate;
};

export const parseMonthDatepicker = (date) => {
  const { month, year } = parseMonthIntToObj(date);

  return `${MONTHS_SHORT[month]}, ${year}`;
};

export const parseDateToYYYYMM = (date, lessMonth = 0) => {
  const subDate = subMonths(date, lessMonth);
  const year = subDate.getFullYear();
  const month = subDate.getMonth() + 1;

  return `${year}${month < 10 ? `0${month}` : month}`;
};

export const isFormatDateDDMonYYYY = (date) => date
  .match(/^(([0-9])|([0-2][0-9])|([3][0-1])) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/gm);

export const isFormatDateDDMMYYYY = (date) => date
  .match(/^(([0-9])|([0-2][0-9])|([3][0-1]))[.](0?[1-9]|1[012])[.]\d{4}$/gm);

export const isFormatDateYYYYMMDD = (date) => date
  .match(/^\d{4}-(0?[1-9]|1[012])-(([0-9])|([0-2][0-9])|([3][0-1]))$/gm);

export const setCaretPosition = (target, pos) => {
  if (target.setSelectionRange) {
    target.focus();
    target.setSelectionRange(pos, pos);
  }
};

export const timeRegex = /^\d\d:[0-5]\d$/g;

export const checkSeparator = (position, backspace) => (
  backspace ? position === 3 : position === 2
);

export const onErrorMouseEnter = (errors, id, error, callback) => {
  error.forEach((item) => {
    const findError = errors.find((elem) => elem.id === id && elem.error === item);

    if (findError) {
      callback({ id: findError.id, message: findError.error, type: ALERT_ERROR });
    }
  });
};

export const isIncludes = (firstValue = '', secondValue = '') => (
  firstValue.toLowerCase().includes(secondValue.toLowerCase())
);

export const isSetTitle = (string, maxWidth) => (
  string.length * 8 > maxWidth ? string : undefined
);

export const capitalizeFirstLetter = (string) => (
  string.charAt(0).toUpperCase() + string.slice(1)
);

export const errorHandler = ({
  error,
  errorsData,
  fieldName,
}) => {
  const { [fieldName]: field, ...errors } = errorsData;

  if (error) {
    errors[fieldName] = error;
  }

  return errors;
};

export const setErrors = ({
  error,
  errorsData,
  fieldName,
  setFieldError
}) => {
  return (dispatch) => {
    const errors = errorHandler({ error, errorsData, fieldName });

    if (errors) {
      dispatch(setFieldError(errors));
    }
  };
};

export const toDateString = (date, isWithoutYear = false) => {
  const dateArray = [...new Date(date).toDateString().split(' ')];

  return isWithoutYear ? `${dateArray[2]} ${dateArray[1]}` : `${dateArray[2]} ${dateArray[1]} ${dateArray[3]}`;
};

export const parsePhoneNumber = (phone) => {
  return phone.replace('+38', '').split(/(\d\d\d)(\d\d\d)(\d\d)(\d\d)/).join(' ');
};

export const validateInputDatepicker = (value) => {
  return value.match(/^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/);
};

export const validateTimeInput = (value, callback, step = 0) => {
  if (isLogTime(value)) {
    const seconds = parseWorklogTimeToSeconds(value);

    callback(toHoursAndMinutes(seconds + step));
  }
};

export const getApiErrors = (errors) => {
  const result = [];

  Object.keys(errors).forEach((key) => {
    if (key === 'message') {
      result.push({
        id: key, message: errors[key], type: ALERT_ERROR, isTranslate: false
      });
    } else {
      errors[key].forEach((error, index) => {
        result.push({ id: `${key}_${index}`, message: error, type: ALERT_ERROR });
      });
    }
  });

  return result;
};

export const getThisYear = () => {
  return new Date().getFullYear();
};

export const getVacationDays = (days, isLeft) => {
  let result = '';

  if (days === 1) {
    result = `day${isLeft ? ' left' : ''}`;
  } else {
    result = `days${isLeft ? ' left' : ''}`;
  }

  return result;
};

export const getDiffDays = (date, toDate = new Date()) => {
  toDate.setHours(0, 0, 0, 0);
  const fromDate = new Date(date);
  const diffTime = Math.abs(fromDate - toDate);

  return Math.ceil(diffTime / (
    1000 * 3600 * 24
  ));
};

export const isExpired = (date) => {
  return new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
};

export const getBusinessDatesCount = (startDate, endDate, holidays = []) => {
  let count = 0;
  const curDate = new Date(startDate);
  const numberDays = differenceInCalendarDays(endDate, startDate);

  const isWorkday = (day) => {
    const isHoliday = holidays.some((holiday) => holiday.toDateString() === curDate.toDateString());

    return !(isWeekend(day) || isHoliday);
  };

  for (let i = 0; i <= numberDays; i += 1) {
    if (isWorkday(curDate)) {
      count += 1;
    }

    curDate.setDate(curDate.getDate() + 1);
  }

  return count;
};

export const getCoords = (elem) => {
  const box = elem.getBoundingClientRect();

  const {
    body, documentElement: {
      scrollTop, scrollLeft, clientTop, clientLeft
    }
  } = document;

  const newScrollTop = window.scrollY || scrollTop || body.scrollTop;
  const newScrollLeft = window.scrollX || scrollLeft || body.scrollLeft;

  const newClientTop = clientTop || body.clientTop || 0;
  const newClientLeft = clientLeft || body.clientLeft || 0;

  const top = box.top + newScrollTop - newClientTop;
  const left = box.left + newScrollLeft - newClientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
    right: box.right,
    spaceToTop: box.top,
    spaceToBottom: window.innerHeight - box.bottom,
    spaceToRight: window.innerWidth - box.left,
    spaceToLeft: box.left,
    width: box.width,
    height: box.height,
  };
};

export const generateArrayOfYears = (min = 0) => {
  const max = new Date().getFullYear();
  const years = [];

  if (min > max) {
    years.push(max);
  } else {
    for (let i = max; i >= min; i -= 1) {
      years.push(i);
    }
  }

  return years;
};

export const roundOffDays = (days, prefix) => {
  let result = `${prefix ? `${prefix} ` : ''}`;
  const weeks = Math.floor(days / 7);
  const month = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    result += `${years} ${years === 1 ? 'year' : 'years'}`;
  } else if (month > 0) {
    result += `${month} ${month === 1 ? 'month' : 'months'}`;
  } else if (weeks > 0) {
    result += `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`;
  } else {
    result += `${days} ${days === 1 ? 'day' : 'days'}`;
  }

  return result;
};

export const addSecondsToDate = (seconds, date = new Date()) => {
  date.setSeconds(date.getSeconds() + seconds);

  return date;
};

export const debounce = (func, wait = 500) => {
  let timeout;

  const resultFunc = (...args) => {
    const later = () => {
      timeout = null;
      func.apply(this, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };

  resultFunc.cancel = () => {
    clearTimeout(timeout);
  };

  return resultFunc;
};

const luhnCheck = (val) => {
  let sum = 0;

  for (let i = 0; i < val.length; i += 1) {
    let intVal = parseInt(val.substr(i, 1));

    if (i % 2 === 0) {
      intVal *= 2;

      if (intVal > 9) {
        intVal = 1 + (intVal % 10);
      }
    }

    sum += intVal;
  }
  return (sum % 10) === 0;
};

export const isCardNumber = (number) => {
  let result = false;
  const regex = /^\d{16}$/;

  if (regex.test(number)) {
    result = luhnCheck(number);
  }

  return result;
};

export const isSelectNewDate = (day, datepicker) => {
  const isBeforeFirstDay = isBefore(day, datepicker.from);
  const isBetweenDays = (datepicker.from && datepicker.to && day >= datepicker.from && day <= datepicker.to);

  return isBeforeFirstDay || isBetweenDays || !datepicker.isSelectRange;
};

export const getVacationDaysLeft = (data, isUpcoming) => {
  const date = isUpcoming ? data.start_date : data.end_date;
  let diffDays = getDiffDays(date);

  if (isUpcoming) {
    diffDays -= 1;
  }

  return `${diffDays} ${getVacationDays(diffDays, true)}`;
};

export const parseHolidaysData = (holidays, startDay, endDay) => {
  const today = new Date();

  return holidays.map((holiday) => {
    const tempDate = new Date(holiday.date.split('.')[0]);

    if (holiday.type === FIXED_HOLIDAY) {
      if (startDay?.getMonth() === tempDate.getMonth()) {
        tempDate.setFullYear(startDay.getFullYear());
      } else if (endDay?.getMonth() === tempDate.getMonth()) {
        tempDate.setFullYear(endDay.getFullYear());
      } else {
        tempDate.setFullYear(today.getFullYear());
      }
    }

    return tempDate;
  });
};

export const createQueryUrl = (keys, data, replace = false) => {
  let { query } = Router;

  keys.forEach((key, index) => {
    const queryKeys = Object.keys(query);

    if (
      queryKeys.includes(key) && (typeof data[index] === 'boolean' || data[index] === null || data[index]?.length === 0)
    ) {
      const filteredQuery = queryKeys.filter((queryKey) => queryKey !== key);

      query = filteredQuery.reduce((res, k) => (
        Object.assign(res, { [k]: query[k] })
      ), {});
    } else if (data[index] && data[index]?.length !== 0) {
      query = {
        ...query,
        [key]: data[index]
      };
    }
  });

  if (replace) {
    Router.replace(
      { query },
      undefined,
      { shallow: true }
    );
  } else {
    Router.push(
      { query },
      undefined,
      { shallow: true }
    );
  }
};

export const parseReviewData = (data, newData, errors, approvedIds = []) => {
  const newErrors = [];
  const tempData = [];

  if (approvedIds.length > 0) {
    const filteredData = data.filter((item) => !approvedIds.includes(item.id));
    tempData.push(...filteredData);

    newData.forEach((newWorkLog, index) => {
      const workLogIndex = tempData.findIndex((workLog) => workLog.id === newWorkLog.id);

      if (workLogIndex === -1) {
        tempData.splice(index, 0, newWorkLog);
      }
    });
  } else {
    tempData.push(
      ...newData.map((worklog) => {
        const autoBilledItem = worklog.milestone_items?.length > 0 && worklog.status === REVIEW_STATUS_NEW
          ? worklog.milestone_items.find((item) => item.auto_billed) : null;

        const tempWorklog = { ...worklog };

        if (autoBilledItem) {
          tempWorklog.milestone_item = autoBilledItem;
          tempWorklog.billed_time = worklog.reported_time;
        }

        if (worklog.status === REVIEW_STATUS_NEW) {
          tempWorklog.approved_time = worklog.reported_time;
        }

        return tempWorklog;
      })
    );
  }

  tempData.forEach((item) => {
    if (item.billed_time !== item.approved_time && !isError(errors, item.id, ['billedTimeError'])) {
      newErrors.push({
        id: item.id,
        error: 'billedTimeError'
      });
    }
    if (item.billed_time > item.reported_time && !isError(errors, item.id, ['billedMoreError'])) {
      newErrors.push({
        id: item.id,
        error: 'billedMoreError'
      });
    }
  });

  return { tempData, newErrors };
};

export const checkSubmit = (submitArray, submitId, type) => {
  return submitArray.some((item) => item.id === submitId && item.type === type);
};

export const setAndCheckData = ({
  errorsObject,
  setFieldData,
  setFieldError,
  validationFunction,
  fieldName,
  fieldData,
  dependencies = {},
}) => {
  return (dispatch) => {
    const fieldError = validationFunction(fieldName, fieldData, dependencies);

    batch(() => {
      dispatch(setFieldData({ [fieldName]: fieldData }));
      dispatch(setErrors({
        error: fieldError,
        errorsData: errorsObject,
        fieldName,
        setFieldError
      }));
    });
  };
};

export const popRouterHistoryState = (query, filters, params) => {
  const tempQuery = { ...query };
  const queryKeys = Object.keys(query);

  params.forEach(({
    type,
    data
  }) => {
    switch (type) {
      case 'boolean':
        data.forEach((param) => {
          if (!queryKeys.includes(param) && filters[param]) {
            tempQuery[param] = false;
          }
        });
        break;
      case 'array':
        data.forEach((param) => {
          if (!queryKeys.includes(param) && filters[param].length > 0) {
            tempQuery[param] = [];
          }
        });
        break;
      default:
        break;
    }
  });

  return tempQuery;
};

export const getLocaleString = (
  date,
  method = 'toLocaleDateString',
  locales = 'ru',
) => {
  const defaultDateOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };
  const defaultTimeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const options = method === 'toLocaleDateString' ? defaultDateOptions : defaultTimeOptions;

  return new Date(date)[method](locales, options);
};

export const onSort = (sortName, sort, setSort) => {
  return (dispatch) => {
    let newSortName = sortName;

    if (newSortName === sort || `-${sortName}` === newSortName) {
      newSortName = `${sort.includes('-') ? '' : '-'}${sortName}`;
    }

    dispatch(setSort(newSortName));
  };
};

export const createSortData = (sortName, sort) => {
  return (sort === sortName || `${sort.slice(1)}` === sortName) ? sort : '';
};

export const numberFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const parseNumber = (number) => {
  return numberFormatter.format(number);
};

export const getInvoicePeriod = (workLogs) => {
  let period = '';
  const dates = workLogs.map(({ date }) => new Date(date));
  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));
  const minYear = minDate.getFullYear();
  const maxYear = maxDate.getFullYear();
  const minMonth = minDate.getMonth();
  const maxMonth = maxDate.getMonth();

  if (minMonth === maxMonth) {
    period = `${MONTHS_SHORT[minMonth]} ${minYear}`;
  } else {
    period = `${MONTHS_SHORT[minMonth]} ${minYear} - ${MONTHS_SHORT[maxMonth]} ${maxYear}`;
  }

  return period;
};

export const combinePermissionsByGroup = (permissions = []) => {
  return permissions.reduce((acc, item) => {
    const group = acc[item.group] || [];

    group.push(item);
    acc[item.group] = group;

    return acc;
  }, {});
};

export const parseDateToMonth = (date) => {
  const strDate = date.toString();
  const year = strDate.slice(0, 4);
  const month = MONTHS_SHORT[strDate.slice(4) - 1];

  return `${month} ${year}`;
};

export const getMonthIntFromDate = (date = new Date()) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  const monthInt = +(`${year}${month < 9 ? `0${month + 1}` : month + 1}`);

  return { monthInt, month, year };
};

export const parseSalaryBonusData = (data) => {
  return data.map((item) => {
    const { month: monthIndex, year } = parseMonthIntToObj(item.month);

    return {
      ...item,
      amount: numberFormatter.format(item.amount),
      formatCreatedAt: format(new Date(item.created_at), 'dd.MM.yyyy'),
      monthIndex,
      year,
    };
  });
};

export const parseVacationInfoData = (data = [], isUpcoming = false) => {
  return data.map((item) => ({
    ...item,
    date: `${toDateString(new Date(item.start_date), true)} - ${toDateString(new Date(item.end_date), true)}`,
    daysLeft: getVacationDaysLeft(item, isUpcoming),
  }));
};

export const getFollowList = (data) => {
  const followList = [];
  const unfollowList = [];

  data.forEach((item) => {
    if (item.follow) {
      followList.push(item);
    } else {
      unfollowList.push(item);
    }
  });

  return { followList, unfollowList };
};

export const removeDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);

  return result;
};

export const getStartOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getEndOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getPeriod = (startDate, endDate) => {
  let period = '';
  const dayMonth = 30;
  const monthYear = 12;

  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : new Date();
  const month = Math.floor((end.getDate() - start.getDate())
    / dayMonth + end.getMonth() - start.getMonth()
    + (monthYear * (end.getFullYear() - start.getFullYear())));

  if (month < monthYear) {
    period = `${month} month`;
  } else {
    const year = Math.floor(month / monthYear);
    const mon = month - year * monthYear;
    period = `${year} year${mon > 0 ? ` ${mon} month` : ''}`;
  }

  return period;
};

export const parseToPercent = (time) => {
  return Math.floor(time * 100);
};

export const getChartMonths = () => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear().toString().slice(2);
  const lastYear = (new Date().getFullYear() - 1).toString().slice(2);
  const monthsLastYear = [];
  const monthsCurrentYear = [];

  MONTHS_SHORT.forEach((item, index) => (index >= currentMonth
    ? monthsLastYear.push(`${item}' ${lastYear}`)
    : monthsCurrentYear.push(`${item}' ${currentYear}`)));

  return [...monthsLastYear, ...monthsCurrentYear];
};

export const getChartHours = (logs) => {
  const charts = { billed_time: [], sickness: [], vacation: [] };

  const newLogs = logs.map((item) => ({ ...item, month: MONTHS_SHORT[item.month.slice(5) - 1] }));

  getChartMonths().forEach((item) => {
    const data = newLogs.find((obj) => item.slice(0, 3) === obj.month);

    Object.keys(charts).forEach((key) => {
      if (data) {
        charts[key].push(data[key] / SECONDS_IN_HOUR);
      } else {
        charts[key].push(0);
      }
    });
  });

  return charts;
};

export const stackDatesChosen = (startDate, days) => {
  const stackDates = [];

  for (let i = 0; i < days; i += 1) {
    const newDate = new Date(startDate);
    stackDates.push(parseDatepicker(new Date(newDate.setDate(newDate.getDate() + i))));
  }

  return stackDates;
};

export const getArraySum = (array) => {
  return array.reduce((acc, item) => acc + item, 0);
};

export const getPercentage = (value, total) => {
  return ((value / total) * 100).toFixed(2);
};

export const maxYAxes = (arrayNum1, arrayNum2) => {
  let maxNum;
  if (Math.max(...arrayNum1) < 6) {
    if (arrayNum2 < 6) {
      maxNum = 8;
    } else {
      maxNum = arrayNum2 + 2;
    }
  } else if (arrayNum2 > Math.max(...arrayNum1)) {
    maxNum = arrayNum2 + 2;
  } else {
    maxNum = Math.max(...arrayNum1) + 2;
  }
  return Math.round(maxNum);
};

export const getArraysRatio = (arrNum1, arrNum2) => getPercentage(getArraySum(arrNum1), getArraySum(arrNum2));

export const listToTreeSubject = (data) => {
  let node;
  const roots = [];
  const mapIds = [];

  const tempData = data.map((item, index) => {
    mapIds[item.id] = index; // initialize the map

    return { ...item, children: [] };
  });

  for (let i = 0; i < data.length; i += 1) {
    node = tempData[i];
    node.parent = tempData[mapIds[node.parent_id]] || null;

    if (node.parent_id) {
      tempData[mapIds[node.parent_id]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
};

export const createDateWithoutTimezoneOffset = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);

  return new Date(year, month - 1, day);
};

export const getDisabledDays = (history) => {
  const pendingDays = [];
  const approvedDays = [];

  history?.forEach((item) => {
    const period = {
      from: createDateWithoutTimezoneOffset(item.start_date),
      to: createDateWithoutTimezoneOffset(item.end_date)
    };

    if (item.status === VACATION_STATUSES.PENDING) {
      pendingDays.push(period);
    }
    if (item.status === VACATION_STATUSES.APPROVED) {
      approvedDays.push(period);
    }
  });

  return { pendingDays, approvedDays };
};

export const dotsInsideString = (str, maxLength, to, from) => {
  if (str.length > maxLength) {
    return `${str.slice(0, to)}...${str.slice(str.length - from, str.length)}`;
  }
  return str;
};

export const trimStringByCharacter = (str, chr) => str.slice(0, str.lastIndexOf(chr) + 1);

export const getSummaryReportTotalHours = (projectSummary) => {
  const total = projectSummary.reduce((acc, item) => acc + item.billed_time, 0);

  return total;
};

export const getSummaryReportTotal = (projectSummary, isApprovedOnly) => {
  let summaryTotal = '';

  if (isApprovedOnly) {
    summaryTotal = projectSummary.reduce((acc, item) => acc + item.approved_time, 0);
  } else {
    Object.keys(CURRENCY_SIGNS).forEach((key) => {
      const obj = {};
      obj[key] = projectSummary.reduce((total, item) => (item.currency === key ? total + item.amount : total), 0);

      if (obj[key] > 0) {
        summaryTotal = `${summaryTotal} ${summaryTotal !== '' ? ',' : ''} ${parseNumber(obj[key])} ${key}`;
      }
    });
  }

  return summaryTotal;
};

export const getDetailsReportTotal = (projectSummary, isApprovedOnly, isBilledOnly) => {
  const { totalBilled, totalApproved } = projectSummary.reduce((acc, item) => {
    acc.totalApproved += item.approved_time;
    acc.totalBilled += item.billed_time;

    return acc;
  }, { totalBilled: 0, totalApproved: 0 });

  const total = {};

  if (isApprovedOnly) {
    total.totalApproved = totalApproved;
  } else if (isBilledOnly) {
    total.totalBilled = totalBilled;
  } else {
    total.totalBilled = totalBilled;
    total.totalApproved = totalApproved;
  }

  return total;
};

export const setTableCheckedItems = ({
  data, event, checked, checkedItem, lastChecked, tableChecked
}) => {
  event.preventDefault();
  event.stopPropagation();
  const { nativeEvent: { shiftKey } } = event;
  let checkedItems;

  if (shiftKey) {
    if (checked && lastChecked) {
      const lastCheckedIndex = data.findIndex((item) => item.id === lastChecked.id);
      const checkedIndex = data.findIndex((item) => item.id === checkedItem.id);
      let sliceData;

      if (checkedIndex > lastCheckedIndex) {
        sliceData = data.slice(lastCheckedIndex, checkedIndex + 1);
      } else {
        sliceData = data.slice(checkedIndex, lastCheckedIndex + 1);
      }

      checkedItems = [...tableChecked, ...sliceData];
    } else if (!checked && lastChecked) {
      const tempData = [...data];
      const lastCheckedIndex = tempData.findIndex((item) => item.id === lastChecked.id);
      const checkedIndex = tempData.findIndex((item) => item.id === checkedItem.id);
      let sliceData;

      if (checkedIndex > lastCheckedIndex) {
        sliceData = tempData.slice(lastCheckedIndex, checkedIndex + 1);
      } else {
        sliceData = tempData.slice(checkedIndex, lastCheckedIndex + 1);
      }

      checkedItems = tableChecked.filter((item) => !sliceData.find((sliceItem) => sliceItem.id === item.id));
    } else {
      checkedItems = [...tableChecked, checkedItem];
    }
  } else if (checked) {
    checkedItems = [...tableChecked, checkedItem];
  } else {
    checkedItems = tableChecked.filter((item) => item.id !== checkedItem.id);
  }

  return ({ checkedItems, checkedItem });
};

export const setTableChecked = ({
  data,
  checked,
  isShift,
  checkedItem,
  lastChecked,
  tableChecked
}) => {
  let checkedItems;

  if (isShift) {
    if (checked && lastChecked) {
      const lastCheckedIndex = data.findIndex((item) => item === lastChecked);
      const checkedIndex = data.findIndex((item) => item === checkedItem);
      let sliceData;

      if (checkedIndex > lastCheckedIndex) {
        sliceData = data.slice(lastCheckedIndex, checkedIndex + 1);
      } else {
        sliceData = data.slice(checkedIndex, lastCheckedIndex + 1);
      }

      checkedItems = [...tableChecked, ...sliceData];
    } else if (!checked && lastChecked) {
      const tempData = [...data];
      const lastCheckedIndex = tempData.findIndex((item) => item === lastChecked);
      const checkedIndex = tempData.findIndex((item) => item === checkedItem);
      let sliceData;

      if (checkedIndex > lastCheckedIndex) {
        sliceData = tempData.slice(lastCheckedIndex, checkedIndex + 1);
      } else {
        sliceData = tempData.slice(checkedIndex, lastCheckedIndex + 1);
      }

      checkedItems = tableChecked.filter((item) => !sliceData.find((sliceItem) => sliceItem === item));
    } else {
      checkedItems = [...tableChecked, checkedItem];
    }
  } else if (checked) {
    checkedItems = [...tableChecked, checkedItem];
  } else {
    checkedItems = tableChecked.filter((item) => item !== checkedItem);
  }

  return ({ checkedItems, checkedItem });
};

export const parseWorklogHistory = (worklog) => {
  const { changed_values: changed = {} } = worklog;

  return Object.entries(changed).reduce((acc, [key, value]) => {
    const temp = { key };

    switch (key) {
      case REPORTED_TIME:
        temp.old = toHoursAndMinutes(value.old);
        temp.new = toHoursAndMinutes(value.new);
        break;
      case DATE:
        temp.old = formatStringDate(value.old, 'dd.MM.yyyy');
        temp.new = formatStringDate(value.new, 'dd.MM.yyyy');
        break;
      case UPDATED_AT:
        temp.old = `${formatStringDate(value.old, 'dd.MM.yyyy HH:mm:ss')} `;
        temp.new = `${formatStringDate(value.new, 'dd.MM.yyyy HH:mm:ss')} `;
        break;
      default:
        temp.old = value.old;
        temp.new = value.new;
        break;
    }

    return [...acc, temp];
  }, []);
};

export const getReverseSort = (sort, newSort) => {
  let tempSort = '';

  if ((sort.includes('-') && sort.slice(1, sort.length) === newSort) || sort !== newSort) {
    tempSort = newSort;
  } else {
    tempSort = `-${newSort}`;
  }

  return tempSort;
};

export const isThisMonth = (checkedMonth) => {
  const { month, year } = parseMonthIntToObj();
  const currentMonth = parseToMonthInt(year, month);

  return checkedMonth > currentMonth;
};

export const getCurrencySumStr = (dollarNum, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  });
  let formattedCurrency = formatter.format(dollarNum);

  formattedCurrency = formattedCurrency.replace(/UAH/g, '₴');
  formattedCurrency = formattedCurrency.replace(/\s(?=\d)/g, '');

  return formattedCurrency;
};

export const generateNameAgreementDoc = (text) => `Document ${text?.split('.')[0]}`;

export const getParseComment = (itemComment) => {
  return itemComment.comment.split(' ')
    .reduce((accumulator, str) => {
      accumulator.push(
        str.match(/(https?:\/\/[^\s]+)/g)
          ? (
            <a
              href={(str[0] === 'w' ? '//' : '') + str}
              key={str}
              target="_blank"
              rel="noreferrer"
            >
              {`${str} `}
            </a>
          )
          : `${str} `
      );

      return accumulator;
    }, []);
};

export const parseBillingStatistic = (billing) => {
  return billing.reduce((billingAcc, billingItem) => {
    const parseItem = Object
      .keys(billingItem)
      .filter((key) => key !== 'currency')
      .reduce((acc, key) => {
        return {
          ...acc,
          [key]: billingItem[key] > 0 ? getCurrencySumStr(billingItem[key], billingItem.currency) : '-'
        };
      }, {});

    return [...billingAcc, parseItem];
  }, []);
};

export const clamp = (value, [min, max]) => {
  return Math.min(max, Math.max(min, value));
};

export const selectItem = (value, arr, findBy = 'id') => {
  let tempArray = [];

  if (value) {
    const selectedArray = [...arr];
    const isArrOfString = typeof value === 'string';
    const find = isArrOfString
      ? selectedArray.includes(value)
      : selectedArray.find((item) => item[findBy] === value[findBy]);

    tempArray = find ? selectedArray.filter((item) => {
      return isArrOfString ? item !== value : item[findBy] !== value[findBy];
    }) : [...selectedArray, value];
  }

  return tempArray;
};

export const parseCurrency = (currency) => CURRENCY_SIGNS[currency];

export const generateServiceRate = (amount, currency) => {
  if (amount || amount === 0) {
    return `${parseCurrency(currency)}${parseNumber(amount)}`;
  }

  return null;
};

export const dateInMs = (date) => {
  const from = date.split('.');
  const today = new Date(from[2], from[1] - 1, from[0]);
  return today.getTime();
};

export const getUpperFirst = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const percentOf = (x, y) => {
  return (x / y) * 100;
};

export const lastDayOfNextQuarter = (date) => {
  const quarter = Math.floor((new Date(date).getMonth()) / 3 + 1);
  const firstDate = new Date((new Date(date)).getFullYear(), quarter * 3, 1);

  return new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
};

export const getProjectUnderNDA = (value, projectNamesNDA) => {
  const newStr = value.replace(/[^a-zA-Z0-9\u0400-\u04FF]/g, '').toLowerCase();
  const projects = projectNamesNDA.map((item) => item.replace(/[^a-zA-Z0-9\u0400-\u04FF]/g, '').toLowerCase());

  return projectNamesNDA[projects.findIndex((item) => newStr.includes(item.toLowerCase()))] || '';
};

export const parseJsonOrGetString = (stringToParse) => {
  try {
    return JSON.parse(stringToParse);
  } catch (e) {
    return stringToParse;
  }
};

export const getMonthIntDifference = (monthLeft, monthRight) => {
  const yearLeft = Math.floor(monthLeft / 100);
  const monthNumberLeft = monthLeft % 100;

  const yearRight = Math.floor(monthRight / 100);
  const monthNumberRight = monthRight % 100;

  const dateLeft = new Date(yearLeft, monthNumberLeft - 1);
  const dateRight = new Date(yearRight, monthNumberRight - 1);

  return differenceInCalendarMonths(dateLeft, dateRight);
};

export const addMonthsInt = (monthInt, amount) => {
  const year = Math.floor(monthInt / 100);
  const monthNumber = monthInt % 100;

  const date = new Date(year, monthNumber - 1);

  const newDate = addMonths(date, amount);
  const newYear = newDate.getFullYear();
  const newMonthNumber = newDate.getMonth() + 1;

  return newYear * 100 + newMonthNumber;
};

export const subMonthsInt = (monthInt, amount) => {
  const year = Math.floor(monthInt / 100);
  const monthNumber = monthInt % 100;

  const date = new Date(year, monthNumber - 1);

  const newDate = subMonths(date, amount);
  const newYear = newDate.getFullYear();
  const newMonthNumber = newDate.getMonth() + 1;

  return newYear * 100 + newMonthNumber;
};

export const roundToNearest = (number) => {
  const nearest = 10 ** Math.floor(Math.log10(number));
  return Math.ceil(number / nearest) * nearest;
};

export const getProjectDocumentTypeByLink = (link = '') => {
  const types = [
    { regex: /docs\.google\.com\/spreadsheets/, type: ESTIMATION },
    { regex: /atlassian\.net\/jira\/software\/c\/projects\/.*\/boards\/.*\?modal/, type: PRESALE_TICKET },
    { regex: /atlassian\.net\/browse/, type: PRESALE_TICKET },
    { regex: /projects\/EST\/tickets/, type: PRESALE_TICKET },
    { regex: /atlassian\.net\/jira\/software\/c\/projects/, type: JIRA_PROJECT },
    { regex: /github|gitlab|bitbucket/, type: REPOSITORIES },
    { regex: /docs\.google\.com\/document/, type: AGREEMENT },
    { regex: /miro\.com|duscholux.atlassian.net/, type: ROAD_MAP },
    { regex: /drive\.google\.com\/drive\/folder/, type: GOOGLE_FOLDER },
    { regex: /works\.onix-systems\.com\/project-details/, type: PROJECT_LINK_ON_WORKS },
    { regex: /\.staging\.onix\.ua/, type: STAGING_SERVER },
    { regex: /slack\.com/, type: SLACK_CHANNEL },
  ];

  return types.find(({ regex }) => regex.test(link))?.type || 'other';
};

export const getSchemaParams = (item) => {
  const {
    lead_approved_hours: leadApproved, work_hours: workHours, schema,
    schema_params: {
      percent, lo_rate: lowRate, hi_rate: highRate
    }
  } = item;

  let rate = null;

  if (schema === GMP_SCHEMA_PARAMS) {
    rate = `${parseToPercent(percent)}%`;
  }

  if (schema === BHR_SCHEMA_PARAMS) {
    const isLowRate = leadApproved > (workHours / 2);

    rate = getCurrencySumStr(isLowRate ? lowRate : highRate);
  }

  return rate;
};

// export const isUseBuilder = (statusDescriptionAnomaly) => {
//   return statusDescriptionAnomaly[0] && !!statusDescriptionAnomaly[0].includes(IS_USE_BUILDER_FLAG);
// };

/**
 * Check if a file is an image based on its MIME type.
 * @param {File} file - The file to check.
 * @returns {boolean} - `true` if the file is an image, `false` otherwise.
 */
export const isImage = (file) => {
  if (!file?.name) {
    return false;
  }

  const disallowedExtensions = ['.heif', '.heic', '.avif', '.tiff', '.tif'];
  const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp'];
  const fileExtension = `.${file.name.toLowerCase().split('.').pop()}`;

  return !disallowedExtensions.includes(fileExtension)
    && (file.type?.startsWith('image/') || allowedExtensions.includes(fileExtension));
};

/**
 * Get time range from statusDescriptionAnomaly
 * @param statusDescriptionAnomaly {string} like
 * Span 1: Approved Hours != Billed Hours: 60.5 != 52.5(use_builder is true: [2024-05-10 2024-05-31])
 * @returns {{endDate: Date, startDate: Date}}
 */
// export const getTimeRange = (statusDescriptionAnomaly) => {
//   let startDate;
//   let endDate;
//   if (isUseBuilder(statusDescriptionAnomaly)) {
//     // Regular expression to match the dates within the brackets
//     const dateRegex = /\[(\d{4}-\d{2}-\d{2})\s+(\d{4}-\d{2}-\d{2})\]/;
//     const inputString = statusDescriptionAnomaly[0].split(IS_USE_BUILDER_FLAG)[1].trim();
//     const match = inputString.match(dateRegex);
//     if (match) {
//       startDate = new Date(match[1]);
//       endDate = new Date(match[2]);
//     }
//   }
//   return {
//     startDate,
//     endDate
//   };
// };

export const getSearchParams = (inputName, value) => {
  return inputName === 'name' ? { name: value } : { [inputName]: { name: value } };
};

export const isNumber = (value) => {
  return typeof value === 'number' && !Number.isNaN(value);
};

export const parseNotificationWithHTML = (inputString) => {
  const regex = /<[^>]*>.*?<\/[^>]*>/g;
  const matches = inputString.match(regex);

  if (!matches) {
    return [inputString];
  }

  const result = [];
  let lastIndex = 0;

  matches.forEach((match) => {
    const index = inputString.indexOf(match);
    if (index > lastIndex) {
      result.push(inputString.substring(lastIndex, index));
    }

    result.push(<DangerouslySetInnerHTML html={match} />);
    lastIndex = index + match.length;
  });

  if (lastIndex < inputString.length) {
    result.push(inputString.substring(lastIndex));
  }

  return result;
};

export const getAsyncMatchers = (typePrefix) => {
  return {
    isPendingAction: (action) => action.type.startsWith(typePrefix) && action.type.endsWith('pending'),
    isFulfilledAction: (action) => action.type.startsWith(typePrefix) && action.type.endsWith('fulfilled'),
    isRejectedAction: (action) => action.type.startsWith(typePrefix) && action.type.endsWith('rejected'),
  };
};

export const sortByIncludedProperty = (array, includedProperties, property) => {
  function comparator(a, b) {
    const aIndex = includedProperties.indexOf(a[property]);
    const bIndex = includedProperties.indexOf(b[property]);

    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }
    if (aIndex !== -1) {
      return -1;
    }
    if (bIndex !== -1) {
      return 1;
    }
    return 0;
  }

  return [...array].sort(comparator);
};

export const calculateColSpan = (arr) => {
  let colspan = arr.length;

  if (arr.includes('epic')) {
    colspan -= 1;
  }
  if (arr.includes('mergeComments')) {
    colspan -= 1;
  }
  if (arr.includes('total')) {
    colspan -= 1;
  }
  if (arr.includes('billed_time') && arr.includes('approved_time')) {
    colspan -= 2;
  } else {
    colspan -= 1;
  }

  return colspan;
};

export const formatTimeByType = (time, timeFormat = REPORT_TIME_FORMAT_OPTIONS.hours) => {
  if (timeFormat === REPORT_TIME_FORMAT_OPTIONS.percentage) {
    return secondsToHoursRounded(time);
  }

  return secondsToHour(time);
};

export const getActivityValue = (activity) => {
  return activity ? undefined : true;
};

export const isSearchItemInactive = (item) => {
  const status = STATUS in item && item.status === STATUS_INACTIVE;
  const trashed = TRASHED in item && item.status;

  return status || trashed;
};

export const getSubscriptionsCount = (requestData, userId) => {
  const filterFn = (element) => {
    return element.user_id !== userId && element.status === STATUS_PENDING;
  };
  const isEmpty = !requestData || !requestData.length || requestData.length === 0;
  return isEmpty ? 0 : requestData?.filter(filterFn).length;
};

export const isActualMonth = (filters) => {
  const date = new Date();
  const actualMonth = date.getMonth();
  const actualYear = date.getFullYear();
  const { year, month } = parseMonthIntToObj(filters.month);

  return actualMonth <= month && year >= actualYear;
};

export const getMonthForQueryState = () => {
  const date = new Date();
  const fromDate = subMonths(date, 4);
  const toDate = addMonths(date, 1);
  const fromMonth = parseToMonthInt(fromDate.getFullYear(), fromDate.getMonth());
  const toMonth = parseToMonthInt(toDate.getFullYear(), toDate.getMonth());

  return { fromMonth, toMonth };
};

export const getBudgetWarningBadge = (statisticData) => {
  const balancePercent = statisticData?.cost_calculation?.balance_percent;
  let badge = null;

  if (balancePercent > PROJECT_BUDGET_WARNING) {
    badge = 'error';
  }

  if (balancePercent > PROJECT_BUDGET_ATTENTION && balancePercent < PROJECT_BUDGET_WARNING) {
    badge = 'warning';
  }

  return badge;
};

export const prepareRequestBody = (body) => {
  if (Array.isArray(body)) {
    return body
      .map(prepareRequestBody)
      .filter((item) => item !== undefined
        && item !== null
        && item !== false
        && item !== ''
        && !(Array.isArray(item) && item.length === 0));
  } if (typeof body === 'object' && body !== null) {
    return Object.entries(body).reduce((acc, [key, value]) => {
      const cleanedValue = prepareRequestBody(value);

      if (cleanedValue !== undefined
        && cleanedValue !== null
        && cleanedValue !== false
        && cleanedValue !== ''
        && !(Array.isArray(cleanedValue)
          && cleanedValue.length === 0)) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
  }

  return body;
};
